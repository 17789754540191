import { Link } from "gatsby"
import * as React from "react"
import styled from "styled-components"

import Layout from "../components/Layout"
import Section from "../components/Section"
import Seo from "../components/Seo"
import Slice from "../components/Slice"

import img404 from "../images/404.svg"

const Container = styled.div`
  background: linear-gradient(-235deg, #4fa5dd 0%, #0166fe 100%);
  position: relative;
  height: 80vh;

  .message {
    color: ${({ theme }) => theme.palette.white};
    height: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 30px;
    z-index: 100000;

    h1 {
      font-size: 64px;
      margin: 0;
    }

    a {
      text-decoration: none;
    }

    .go-back {
      color: ${({ theme }) => theme.palette.white};
      font-size: 16px;
      display: flex;
      justify-content: center;
      width: 170px;
      text-decoration: none;
      background-color: ${({ theme }) => `${theme.palette.white}30`};
      padding: 10px 25px;
      border-radius: 10px;
      transition: background-color 0.2s;

      :hover {
        background-color: ${({ theme }) => `${theme.palette.white}40`};
      }
    }
  }

  img.not-found {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0;
    width: 100%;
  }

  .slice-container {
    position: absolute;
    bottom: 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    img.not-found {
      width: 60vw;
    }
    .message {
      padding: 0 50px;
    }
  }
`

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Container>
      <div className="message">
        <div>
          <h1>Oh No!</h1>
          <h1>It seems we've lost you</h1>
        </div>
        <Link to="/">
          <div className="go-back">Return home</div>
        </Link>
      </div>
      <img src={img404} className="not-found" />
      <div className="slice-container">
        <Slice color="#222222" height={100} />
      </div>
    </Container>
  </Layout>
)

export default NotFoundPage
